:root {
  --primary-color-1: #062F6E;
  --primary-color-2: #0075C9;
  --primary-color-3: #75AB00;

  --secondary-color-1: #F2B749;
  --secondary-color-2: #F26741;
  --secondary-color-3: #EBF3FA;

  --primary-text-color: #444546;
  --link-text-color: #0075c9;

  --enrollment-bg-color: #F2F4F8;
  --plan-details-table-bg-color: #DFEDFE;
  --plan-details-table-highlight-color: #DEE8C6;

  --main-btn-bg-color: #75AB00;
  --main-btn-bg-color-hover: #4c7217;
  --secondary-btn-bg-color: #00ff00;

  --main-btn-border-radius: 30px;
  --main-btn-color: #FFFFFF;
  --main-btn-font-weight: 500;
  --main-btn-font-size: 1.125rem;
  --signup-btn-color: #F26741;
  --signup-btn-color-hover: #F74515;
  --btn-previous-bg: #8E8F91;

  --input-border-color: #E4E6E8;
  --input-border-selected: #75AB00;
  --input-bg-selected: #DEE8C6;

  --input-placeholder-color: #8E8F91;
  --input-label-color: #444546;
  --input-required-color: #0075C9;
  --input-checkbox-bg: #F1F6FB;

  --hr-color: #F26741;
  --chevron-down: #75AB00;
  --error-color: #C30C14;

  --btn-border-radius: 2.5rem;

  --zip-border-radius: 3.5rem;
  --zip-submit-border-radius: 3rem;
  --zip-placeholder: 2rem;
  --zip-input-text: 2rem;

}